<template>
  <div class="highlightedCardContainer">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.highlightedCardContainer {
  border-radius: 10px;
  border: 1px solid #1e353e;
  background-color: #081b25;
  padding: 14px;
  width: max-content;
  filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.7));
  background-image: radial-gradient(ellipse 100% 50% at 50% 100%, #397082, rgba(0, 0, 0, 0));
}
</style>
