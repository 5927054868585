import type { InfoModal } from '~/utils/constants';

export const infoModal: InfoModal[] = [
  {
    icon: '/img/licenseInfoCards/license-dialog-1.svg',
    textKey: 'licenseMineProduces'
  },
  {
    icon: '/img/licenseInfoCards/license-dialog-2.svg',
    textKey: 'licenseMiningClaims'
  },
  {
    icon: '/img/licenseInfoCards/license-dialog-3.svg',
    textKey: 'licenseOnceYouveReached'
  }
];
